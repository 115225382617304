import { Card, FlexBox, Typography } from '@vp/swan'
import { useLogger, useSwanStyleKeys, useUserContext } from '@vp/ubik-context'
import { Title } from './title'

export interface Props {
  greeting: string
}

export const Fragment = (props: Props) => {
  const logger = useLogger()
  const userContext = useUserContext()
  logger.log('Rendering fragment for a locale', userContext.locale)

  useSwanStyleKeys(['core'])

  const workId = userContext.queryStrings?.['workid']

  return (
    <FlexBox>
      <Card style={{ width: '100%' }}>
        <Title title='ubik-pdc' />
        <Typography textAllCaps fontWeight='bold' mb={1} mr={1}>
          {props.greeting} from ubik-pdc fragment!
        </Typography>

        {!!workId && <Typography>Work ID: {workId}</Typography>}

        <Typography>User Context!!!!</Typography>
        <pre>{JSON.stringify(userContext, null, 2)}</pre>
      </Card>
    </FlexBox>
  )
}
