import { Typography } from '@vp/swan'

export type TitleProps = {
  title: string
}

export const Title = ({ title }: TitleProps) => {
  return (
    <Typography mb={1} mr={1}>
      {title} This component is shared between multiple fragments in the project
    </Typography>
  )
}
